<template>
  <div class="req-temp-container onboarding-admin" v-if="isSetupComplete">
    <vs-row class="items-start">
      <vs-col vs-type="flex" vs-w="12">
        <vs-card class="custom-vx-card">
          <vs-button flat v-round class="flex items-center ml-auto mb-8 send-onboarding" @click="$router.push({ name: 'onboarding-request-create' })">
            <svg width="22" height="22" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M14.1919 0.30806C14.436 0.552138 14.436 0.947866 14.1919 1.19194L7.31694 8.06694C7.07286 8.31102 6.67713 8.31102 6.43305 8.06694C6.18898 7.82287 6.18898 7.42714 6.43305 7.18306L13.3081 0.30806C13.5521 0.0639827 13.9479 0.0639827 14.1919 0.30806Z" fill="white"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M14.1919 0.30806C14.3617 0.477834 14.4192 0.729854 14.3399 0.956471L9.96491 13.4565C9.88025 13.6983 9.65652 13.864 9.40047 13.8745C9.14442 13.8849 8.90794 13.738 8.80386 13.5038L6.40149 8.09851L0.996159 5.69614C0.761984 5.59206 0.615069 5.35558 0.625515 5.09953C0.635961 4.84348 0.801651 4.61975 1.04353 4.53509L13.5435 0.160091C13.7701 0.0807748 14.0222 0.138287 14.1919 0.30806ZM2.94442 5.19413L7.12883 7.05387C7.27023 7.11671 7.38329 7.22977 7.44613 7.37117L9.30587 11.5556L12.7313 1.76873L2.94442 5.19413Z" fill="white"/>
            </svg>
            <span class="ml-2">Send Onboarding Request</span>
          </vs-button>
          <vs-table class="transcation-list" :data="onboardingList">
          <!--!!! table head starts here -->
          <template slot="thead">
              <vs-th>
                <div class="boxBody">
                  <div class="combo-box" @click="changeFilter('onboardingId')">
                    <label class="m-0 p-0 text-base font-medium">Onboarding ID</label>
                    <div class="icon-combo" v-if="!filtersHidden">
                      <vs-icon :class="searchParam.sortBy === 'onboardingId' && searchParam.sortDir === 'asc' ? 'dark' : ''" icon="arrow_drop_up"/>
                      <vs-icon :class="searchParam.sortBy === 'onboardingId' && searchParam.sortDir === 'desc' ? 'dark' : ''" icon="arrow_drop_down"/>
                    </div>
                  </div>
                  <span @click="resetInput($event, 'onboardingId')" v-if="searchParam.onboardingId" class="iconClose">X</span>
                  <vs-input
                    v-if="!filtersHidden"
                    class="w-auto"
                    v-model="searchParam.onboardingId"
                    v-bind:class="{
                      isFocus: searchParam.onboardingId,
                      textBlue: searchParam.onboardingId,
                    }"
                    @keyup="onChangeInput($event)"
                  />
                </div>
              </vs-th>
              <vs-th>
                <div class="boxBody">
                  <div class="combo-box" @click="changeFilter('sendTo')">
                    <label class="m-0 p-0 text-base font-medium">Send to</label>
                    <div class="icon-combo" v-if="!filtersHidden">
                      <vs-icon :class="searchParam.sortBy === 'sendTo' && searchParam.sortDir === 'asc' ? 'dark' : ''" icon="arrow_drop_up"/>
                      <vs-icon :class="searchParam.sortBy === 'sendTo' && searchParam.sortDir === 'desc' ? 'dark' : ''" icon="arrow_drop_down"/>
                    </div>
                  </div>
                  <span @click="resetInput($event, 'sendTo')" v-if="searchParam.sendTo" class="iconClose" >X</span>
                  <vs-input
                    v-if="!filtersHidden"
                    class="w-auto"
                    v-model="searchParam.sendTo"
                    v-bind:class="{
                      isFocus: searchParam.sendTo,
                      textBlue: searchParam.sendTo,
                    }"
                    @keyup="onChangeInput($event)"
                  />
                </div>
              </vs-th>
              <vs-th>
                <div class="boxBody">
                  <div class="combo-box" @click="changeFilter('lastUpdated')">
                    <label class="m-0 p-0 text-base font-medium">Last updated</label>
                    <div class="icon-combo" v-if="!filtersHidden">
                      <vs-icon :class="searchParam.sortBy === 'lastUpdated' && searchParam.sortDir === 'asc' ? 'dark' : ''" icon="arrow_drop_up"/>
                      <vs-icon :class="searchParam.sortBy === 'lastUpdated' && searchParam.sortDir === 'desc' ? 'dark' : ''" icon="arrow_drop_down"/>
                    </div>
                  </div>
                  <date-picker
                    v-bind:class="{ active: searchParam.date }"
                    @change="onChangeInputNoDebounce()"
                    valueType="format"
                    v-if="!filtersHidden"
                    v-model="searchParam.lastUpdated"
                    lang="en"
                    type="date"
                    placeholder="DD/MM/YYYY"
                    class="pay-date"
                    format="DD/MM/YYYY"
                  ></date-picker>
                </div>
              </vs-th>

              <vs-th>
                <div>
                  <div class="combo-box" @click="changeFilter('source')">
                    <label class="m-0 p-0 text-base font-medium">Source</label>
                    <div class="icon-combo" v-if="!filtersHidden">
                      <vs-icon icon="arrow_drop_up" :class="searchParam.sortBy === 'source' && searchParam.sortDir === 'asc' ? 'dark' : ''"/>
                      <vs-icon icon="arrow_drop_down" :class="searchParam.sortBy === 'source' && searchParam.sortDir === 'desc' ? 'dark' : ''" />
                    </div>
                  </div>

                  <vs-select
                    v-if="!filtersHidden"
                    class="w-auto"
                    v-model="searchParam.source"
                    :multiple="true"
                    v-bind:class="{
                      isFocus: searchParam.source,
                      textBlue: searchParam.source,
                    }"
                    @input="onChangeInputNoDebounce($event)"
                    >
                    <div class="selectAllDiv" v-if="searchParam.source">
                      <a class="dropdownSelectAllButton" @click="selectAllSources">  Unselect all</a>
                    </div>
                    <vs-select-item v-for="(item, index) in sourcesArray" :key="index" :text="item" :value="item"/>
                  </vs-select>
                </div>
              </vs-th>
              <vs-th>
                <div class="boxBody">
                  <div class="combo-box" @click="changeFilter('status')">
                    <label class="m-0 p-0 text-base font-medium">Status</label>
                    <div class="icon-combo" v-if="!filtersHidden">
                      <vs-icon :class="searchParam.sortBy === 'status' && searchParam.sortDir === 'asc' ? 'dark' : ''" icon="arrow_drop_up"/>
                      <vs-icon :class="searchParam.sortBy === 'status' && searchParam.sortDir === 'desc' ? 'dark' : '' " icon="arrow_drop_down"/>
                    </div>
                  </div>
                  <vs-select
                    v-if="!filtersHidden"
                    class="w-auto"
                    v-model="searchParam.status"
                    :multiple="true"
                    v-bind:class="{
                      isFocus: searchParam.status,
                      textBlue: searchParam.status,
                    }"
                    @input="onChangeInputNoDebounce($event)"
                    >
                    <div class="selectAllDiv" v-if="searchParam.status">
                      <a class="dropdownSelectAllButton" @click="selectAllStatuses"> Unselect all</a>
                    </div>
                    <vs-select-item v-for="(item, index) in onboardingStatuses" :key="index" :text="item" :value="item"/>
                  </vs-select>
                </div>
              </vs-th>
              <vs-th class="m-0 p-0 text-base font-medium">URL</vs-th>

              <vs-th></vs-th>

              <vs-th class="filter-cross" :class="{ toggle: !filtersHidden }">
                <filter-icon size="1.5x" :fill="filterIconColor.fill" :stroke="filterIconColor.stroke" @click="toggleFilter"></filter-icon>
              </vs-th>
            </template>
            <!--!!! table head ends here -->

            <!-- table body starts here -->
            <template aria-valuemax="" slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <span class="whitespace-nowrap">{{tr.onboardingRequestId}}</span>
                </vs-td>

                <vs-td>
                  {{ tr.merchantName  }} <br>
                  <a @click.prevent="showPopUpForInfoUpdate(tr)">{{ tr.email }}</a>
                </vs-td>

                <vs-td>
                  {{ moment(tr.lastUpdated) }}
                </vs-td>

                <vs-td>
                  <span> {{ tr.source }}</span>
                </vs-td>

                <vs-td>
                  <span :class="tr.onboardingForm ? changeStatusColor(tr.onboardingForm.status) : changeStatusColor('Pending')"> {{ tr.status }}</span>
                </vs-td>

                <vs-td class="align-middle">
                  <vs-tooltip text="Copy link to clipboard" class="pointer">
                    <vs-icon icon="content_copy" size="small" @click="copyLinkToClipBoard(tr._id)" class="clipIcon text-grey"/>
                  </vs-tooltip>
                </vs-td>

                <vs-td :data="tr">
                  <template v-if="tr.onboardingForm && tr.onboardingForm.status == 'Pending' || tr.onboardingForm && tr.onboardingForm.status == 'Application in progress' || !tr.onboardingForm">
                    <a @click="activeWithdrawModel(tr)" class="underline cursor-pointer">Withdraw</a>
                    <br>
                    <span @click="resendOnboardingLink(tr._id)"  class="underline cursor-pointer">Resend</span>
                  </template>
                </vs-td>

                <vs-td>
                  <template v-if="tr.onboardingForm && tr.onboardingForm.status !== 'Pending'">
                    <router-link
                      :to="{
                        name: 'mvpReview',
                        params: { onboardingId: tr._id },
                      }"
                      class="underline cursor-pointer"
                    >View</router-link>
                  </template>
                </vs-td>
              </vs-tr>
            </template>
            <!-- table body ends here -->
          </vs-table>

          <div class="mt-3">
            <v-pagination v-model="searchParam.pageNumber" :page-count="totalPage"></v-pagination>
          </div>
        </vs-card>
      </vs-col>
    </vs-row>

    <!-- <-- for reject -- /> -->
    <vs-popup class="beneficial-popup delete-pop-up" title="Withdraw Application " :active.sync="showWithdrawModel">
      <p class="mb-8"> You are about to withdraw the onboarding request - this will delete any data the merchant may have entered and remove their onboarding account from the system. </p>
      <p class="mb-8"> This action cannot be undone - are you sure you want to proceed? </p>

      <div class="flex justify-end items-center">
        <vs-button @click="deleteOnboarding()" size="large" v-round  class="border-only-btn mr-8">Yes</vs-button>
        <a @click="closeWithdrawModel()" class="mid-blue">No</a>
      </div>
    </vs-popup>

    <!-- Update Onboarding Information  -->
    <vs-popup class="beneficial-popup update-onboarding-user-info" title="Update contact detail" :active.sync="popupActive" @close="closePopup">
      <div class="flex">
        <div class="w-full mt-2">
          <vs-row class="mb-4">
            <vs-col class="pr-6" vs-xs="12">
              <div class="flex mb-8">
                <div class="input-grp">
                  <label>Contact name</label>
                  <vs-input v-model="onboardingInfo.contactName" data-vv-as="contact name" data-vv-scope="updateInfo" v-validate="'required'" name="Contact Name" class="w-full"/>
                  <span v-show="errors.has(`updateInfo.Contact Name`)" class="text-danger text-sm"> {{ errors.first(`updateInfo.Contact Name`) }} </span>
                </div>

                <div class="input-grp">
                  <label>Email address</label>
                  <vs-input v-model="onboardingInfo.email" name="Email" data-vv-scope="updateInfo" v-validate="'required|email'" class="w-full"/>
                  <span v-show="errors.has(`updateInfo.Email`)" class="text-danger text-sm"> {{ errors.first(`updateInfo.Email`) }} </span>
                </div>
              </div>
            </vs-col>
          </vs-row>

          <vs-row class="justify-end items-center">
            <vs-button size="large" v-round class="mr-8" @click="handleUpdateOnboardingUserInfo">Update</vs-button>
            <a class="mid-blue" @click="closePopup"><u>Cancel</u></a>
          </vs-row>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import vPagination from "@/views/components/pagination";
import { FilterIcon } from "vue-feather-icons";
import _ from "lodash";
import DatePicker from "vue2-datepicker";

export default {
  components: { vPagination, FilterIcon ,DatePicker},
  name: "index",
  data() {
    return {
      filtersHidden: true,
      isSetupComplete: false,
      limitValue: [
        { text: "1", value: 1 },
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
      pageNumber: 1,
      totalData: 0,
      limit: 50,
      onboardingList: [],
      showWithdrawModel:false,
      withdrawData:{},
      searchParam: {
        onboardingId: "",
        sendTo: "",
        lastUpdated: "",
        source: "",
        status: "",
        sortBy: "createdAt",
        sortDir: "desc",
        pageNumber: 1,
        totalData: 0,
        limit: 50,
      },
      sourcesArray: [],
      onboardingStatuses: [
        "Application in progress",
        "Approved",
        "Awaiting activation",
        "Awaiting approval",
        "Can't match OB",
        "Completed",
        "Draft",
        "Error provisioning account",
        "Error sending to till",
        "IDV Complete",
        "IDV Failed",
        "Info Requested",
        "Pending",
        "Provisioning error",
        "Rejected",
        "Resubmitted",
        "Waiting for till",
        "Waiting on IDV",
      ],
      onboardingInfo: {
        onboardingRequestId: "",
        contactName: "",
        email: ""
      },
      popupActive: false,
      onboardingAppUrl: process.env.VUE_APP_ONBOARDING_APP,
    };
  },

  mounted() {
    this.getOboardingRequestsList();
  },

  watch:{
    "searchParam.pageNumber"() {
      this.getOboardingRequestsList();
    },
  },

  methods: {
    ...mapActions("onboardingAdmin", ["fetchOnboardingRequestsList", "deleteOnboardingForm", "resendOnboardingLinkToMerchant", "updateOnboardingUserInfo"]),

    onChangeInput: _.debounce(function (e) {
      this.getOboardingRequestsList();
    }, 600),

    onChangeInputNoDebounce(){
      this.getOboardingRequestsList();
    },

    selectAllSources(){
      this.searchParam.source = ""
      this.getOboardingRequestsList()
    },
    
    selectAllStatuses(){
      this.searchParam.status = ""
      this.getOboardingRequestsList()
    },

    // Methods related to filter
    toggleFilter() {
      this.filtersHidden = !this.filtersHidden;
      if (this.filtersHidden === true) {
        this.searchParam = {
          pageNumber: 1,
          totalData: 0,
          limit: 50,
          source: "",
          onboardingId: "",
          sendTo: "",
          lastUpdated: "",
          status: [""],
          sortBy: "createdAt",
          sortDir: "desc",
        };
        this.getOboardingRequestsList();
      }
    },

    changeFilter(value) {
      this.searchParam.sortBy = value;
      this.searchParam.sortDir = this.searchParam.sortDir === "desc" ? "asc" : "desc";
      this.getOboardingRequestsList();
    },

    resetInput(event, id) {
      if (id == "status") {
        this.searchParam.status = "";
      } else if (id == "source") {
        this.searchParam.source = "";
      } else if (id == "sendTo") {
        this.searchParam.sendTo = "";
      } else if (id == "onboardingId") {
        this.searchParam.onboardingId = "";
      } else if (id == "lastUpdated") {
        this.searchParam.lastUpdated = "";
      }
      this.getOboardingRequestsList();
    },

    // filter  methods ends here

    moment(date) {
      return moment(date).format("DD/MM/YYYY (HH:mm:ss)");
    },

    activeWithdrawModel(tr) {
      this.showWithdrawModel = true;
      this.withdrawData = tr;
    },

    closeWithdrawModel(tr) {
      this.showWithdrawModel = false;
    },

    async getOboardingRequestsList() {
      this.$vs.loading();

      const data = {
        searchParam: this.searchParam,
      };
      await this.fetchOnboardingRequestsList(data)
        .then((result) => {
          this.onboardingList = result.data.data.docs;
          this.onboardingList.forEach((element) => {
            if (element.adminDetails || element.introducerDetails) {
              let  match = element.adminDetails ? element.adminDetails.fullName : element.introducerDetails.name;
              if (!this.sourcesArray.includes(match)) {
                if (element.adminDetails) {
                  this.sourcesArray.push(element.adminDetails.fullName)
                } else if (element.createdFrom == 'Introducer' && element.introducerDetails) {
                  this.sourcesArray.push(element.introducerDetails.name);
                }

                this.sourcesArray = this.sourcesArray.sort((a, b) => {
                  return a.toLowerCase().localeCompare(b.toLowerCase());
                });
              }
            }
          });

          this.totalData = result.data.data.pagination.total ? result.data.data.pagination.total : 0;
          this.isSetupComplete = true;
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Notification",
            text: err.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },

    changeStatusColor(status){
      let bgClass = "";
      if(["Waiting on IDV", "Application in progress"].includes(status)){
        bgClass = "badge info-border"
      }else if(["Waiting for till"].includes(status)){
        bgClass = "badge info-dark-border"
      }else if(["Awaiting activation"].includes(status)){
        bgClass = "badge success"
      }else if(["Awaiting approval", "IDV Complete"].includes(status)){
        bgClass = "badge info"
      }else if(status == "Completed" || status == "Approved"){
        bgClass = "badge success";
      }else if(status == "Rejected"){
        bgClass = "badge danger-border";
      }else if(["Error sending to Till", "Error provisioning account", "Provisioning error", "Can't match OB", 'IDV Failed'].includes(status)){
        bgClass = "badge danger-red";
      }else if(["Info Requested"].includes(status)){
        bgClass = "badge info-border"
      }else if(["Resubmitted"].includes(status)){
        bgClass = "badge info"
      }
      else{
        bgClass = "badge default-border";
      }
      return bgClass;
    },

    async deleteOnboarding() {
      await this.deleteOnboardingForm(this.withdrawData).then((result) => {
        this.showWithdrawModel = false;
        this.showMessage("Success", `Onboarding request ${result.data.data.onboardingRequestId} has been withdrawn successfully.`, "success", "icon-check-circle");
        this.getOboardingRequestsList();
      }).catch((ex)=> {
        this.showMessage("Error", "We are unable to process your request", "error", "icon-times-circle");
      });
    },
    
    showMessage(title, message, color, icon) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,
      });
    },

    async resendOnboardingLink(value){
      const data = {id:value};
      await this.resendOnboardingLinkToMerchant(data).then((response)=>{
          this.$vs.notify({
          title: "Success",
          text: `Onboarding request ${response.data.data.onboardingRequestId.onboardingRequestId} re-sent successfully.`,
          color: "success",
          iconPack: "feather",
          position: "top-right",
          icon: "icon-check-circle",
        });
      }).catch((err)=>{
        console.log(err);
      });
    },

    showPopUpForInfoUpdate(row) {
      this.popupActive = true;
      this.onboardingInfo.contactName = row.contactName;
      this.onboardingInfo.email = row.email;
      this.onboardingInfo.onboardingRequestId = row._id;
    },

    closePopup() {
      this.popupActive = false;
      this.onboardingInfo.contactName = "";
      this.onboardingInfo.email = "";
      this.$validator.reset();
    },

    handleUpdateOnboardingUserInfo() {
      const { onboardingRequestId, ...payload } = this.onboardingInfo;
      this.$validator.validate("updateInfo.*")
        .then((response) => {
          if (response) {
            this.updateOnboardingUserInfo({ param: onboardingRequestId, data: payload })
              .then(async (response) => {
                this.closePopup();
                await this.getOboardingRequestsList();
                this.$vs.notify({
                  title: "Onboarding request",
                  text: 'User information updated.',
                  color: "success",
                  iconPack: "feather",
                  position: "top-right",
                });
              })
              .catch((error) => {
                this.popupActive = false;
                this.$vs.notify({
                  title: "Onboarding request",
                  text: 'Unable to update.',
                  color: "warning",
                  iconPack: "feather",
                  position: "top-right",
                });
              });
          }
        })
        .catch((er) => {
          console.log("e", er);
        });
    },

    copyLinkToClipBoard(onboardingRequestId) {
      navigator.clipboard.writeText(`${this.onboardingAppUrl}${onboardingRequestId}/authenticate`).then(() => {
        this.showToastMessage("Link copied to clipboard", "", "success");
      }).catch((ex) => {
        this.showToastMessage("Error copying link", `Unable to copy link to clipboard. Please try again.`, "error");
      });
    },
  },

  computed: {
    filterIconColor() {
      if (!this.filtersHidden) {
        return { fill: "#0f67f4", stroke: "#0f67f4" };
      }
      return { fill: "white", stroke: "#828282" };
    },

    totalPage: function () {
      return Math.ceil(this.totalData / this.limit);
    },
  },
};
</script>
